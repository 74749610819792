import React, { useEffect, useState } from 'react'
import AdminSidebar from './AdminSidebar';
import AdminHeader from './AdminHeader';

function AdminBookings() {
	const [userr, setUserr] = useState(null);
	const [restaurant, setRestaurant] = useState([]);
  
	useEffect(() => {
	  // Fetch user information
	  fetch("/me")
		.then((response) => response.json())
		.then((user) => setUserr(user));
	}, []);
  
	useEffect(() => {
	  // Fetch user's orders when userr changes
	  if (userr) {
		fetch(`/restaurants`)
		  .then((response) => response.json())
		  .then((rest) => setRestaurant(rest));
	  }
	}, [userr]);

if (userr) {
	
	if (userr.user_type !== "admin") {
		window.location.href = "../";
	  }
}

	// useEffect(() => {
	// 	fetch(`/restaurants/${userr.id}`)
	// 	  .then((r) => r.json())
	// 	  .then((restaurant) => setRestaurant(restaurant))
	// 	  .catch((error) => {
	// 		console.error("Error fetching user:", error);
	// 	  });
	//   }, [userr]);

	
	const orders = restaurant.orders
	


	return (
	  <>
	   {userr && (
    		<div className="main-section">
		<AdminHeader userr={userr}/>
			<div className="page-section account-header buyer-logged-in">
				<div className="container">
					<div className="row">
					<AdminSidebar />
						<div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
					
                        <div className="user-dashboard loader-holder">
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<div className="row">
										<div className="element-title has-border right-filters-row">
											<h5>Recent Bookings</h5>
											<div className="right-filters row pull-right">
												<div className="col-lg-6 col-md-6 col-xs-6">
													<div className="input-field">
														<select className="chosen-select-no-single">
															<option selected="selected" value="">Select Booking Status</option>
															<option value="Processing">Processing</option>
															<option value="Cancelled">Cancelled</option>
															<option value="Completed">Completed</option>
														</select>
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-xs-6">
													<div className="input-field">
														<i className="icon-angle-down"></i>
														<input type="text" data-id="daterange223" id="daterange" value="" placeholder="Select Date Range"/>
													
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<div className="user-orders-list responsive-table">
											<ul className="table-generic" id="portfolio">
												<li>
													<div className="orders-title">Customer Name</div>
													<div className="orders-date">Date</div>
													<div className="orders-type">Status</div>
													<div className="orders-price">Detail</div>
												</li>
												<li>
													<div className="orders-title">
														<h6 className="order-title">
															<a  data-toggle="modal" data-target="#booking-detail-22603">Buyer Demo </a>
															<span>( #22603 )</span>
														</h6>
													</div>
													<div className="orders-date">
														<span>Apr 9,2019</span>
													</div>
													<div className="orders-status">
														<span className="booking-status" style={{backgroundColor: "#047a06"}} >Completed</span>
													</div>
													<div className="orders-price">
														<a  data-toggle="modal" data-target="#booking-detail-22603"><i className="icon-plus2 text-color"></i></a>
													</div>
												</li>
												<li>
													<div className="orders-title">
														<h6 className="order-title">
															<a  data-toggle="modal" data-target="#booking-detail-22603">Buyer Demo </a>
															<span>( #22600 )</span>
														</h6>
													</div>
													<div className="orders-date">
														<span>Apr 9,2019</span>
													</div>
													<div className="orders-status">
														<span className="booking-status" style={{backgroundColor: "#1e73be"}} >Processing</span>
													</div>
													<div className="orders-price">
														<a  data-toggle="modal" data-target="#booking-detail-22603"><i className="icon-plus2 text-color"></i></a>
													</div>
												</li>
												<li>
													<div className="orders-title">
														<h6 className="order-title">
															<a  data-toggle="modal" data-target="#booking-detail-22603">Buyer Demo </a>
															<span>( #22445 )</span>
														</h6>
													</div>
													<div className="orders-date">
														<span>Mar 30,2019</span>
													</div>
													<div className="orders-status">
														<span className="booking-status" style={{backgroundColor: "#047a06"}} >Completed</span>
													</div>
													<div className="orders-price">
														<a  data-toggle="modal" data-target="#booking-detail-22603"><i className="icon-plus2 text-color"></i></a>
													</div>
												</li>
												<li>
													<div className="orders-title">
														<h6 className="order-title">
															<a  data-toggle="modal" data-target="#booking-detail-22603">Buyer Demo </a>
															<span>( #22438 )</span>
														</h6>
													</div>
													<div className="orders-date">
														<span>Mar 30,2019</span>
													</div>
													<div className="orders-status">
														<span className="booking-status" style={{backgroundColor: "#047a06"}} >Completed</span>
													</div>
													<div className="orders-price">
														<a  data-toggle="modal" data-target="#booking-detail-22603"><i className="icon-plus2 text-color"></i></a>
													</div>
												</li>
												<li>
													<div className="orders-title">
														<h6 className="order-title">
															<a  data-toggle="modal" data-target="#booking-detail-22603">Buyer Demo </a>
															<span>( #22149 )</span>
														</h6>
													</div>
													<div className="orders-date">
														<span>Mar 15,2019</span>
													</div>
													<div className="orders-status">
														<span className="booking-status" style={{backgroundColor: "#047a06"}} >Completed</span>
													</div>
													<div className="orders-price">
														<a  data-toggle="modal" data-target="#booking-detail-22603"><i className="icon-plus2 text-color"></i></a>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
                    
						</div>
					</div>
				</div>
			</div>
		</div>

        
	   )}

<div className="modal fade menu-order-detail menu-order-info" id="booking-detail-22603" tabindex="-1" role="dialog">
		<div className="modal-dialog">
			<div className="modal-content">
				<div className="modal-header">
					<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
					<h2><a>Booking Detail</a></h2>
				</div>
				<div className="modal-body booking-modal-body">
					<div className="order-detail-inner">
						<h3>Restaurant Demo </h3>
						<ul className="order-detail-options">
							<li>
								<strong>Booking ID :</strong>
								<span>22603</span>
							</li>
							<li>
								<strong>Booking Date :</strong>
								<span> Apr 9,2019 6:40 AM </span>
							</li>
						</ul>
						<h3>Customer Deatil</h3>
						<ul className="order-detail-options">
							<li>
								<strong>First Name:</strong>
								<span>Mark</span>
							</li>
							<li>
								<strong>Last Name:</strong>
								<span>Jose</span>
							</li>
							<li>
								<strong>Email:</strong>
								<span>markjose@gmail.com</span>
							</li>
							<li>
								<span>4-guest</span>
							</li>
							<li>
								<span>Apr 30,2019 11:00 AM</span>
							</li>
							<li className="order-detail-message">
								<strong>Instructions:</strong>
								<span>I need to book a special table for my friends. Make it some special.!</span>
							</li>
						</ul>
						<h3>Booking Status </h3>
						<div className="booking-status-holder">
							<div className="input-field">
								<select className="chosen-select-no-single">
									<option value="Processing">Processing</option>
									<option value="Cancelled">Cancelled</option>
									<option selected="selected" value="Completed">Completed</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    </>
  )
}

export default AdminBookings