import React, { useEffect, useState } from 'react';
import AdminSidebar from './AdminSidebar';
import AdminHeader from './AdminHeader';

function AdminAddfood() {
  const [userr, setUserr] = useState(null);
  const [restaurant, setRestaurant] = useState([]);
  const [cuisines, setCuisines] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    cuisine_id: '',
    foodtype: '',
    restaurant_id: '',
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');


  useEffect(() => {
    fetch("/me")
      .then((response) => response.json())
      .then((user) => {
        setUserr(user);
       
      });
  }, []);


  useEffect(() => {
      fetch(`/cuisines`)
        .then((response) => response.json())
        .then((cuisines) => setCuisines(cuisines));
  }, []);


  useEffect(() => {
    // Fetch user's restaurant when userr changes
    if (userr) {
      fetch(`/restaurants`)
        .then((response) => response.json())
        .then((rest) => {
          setRestaurant(rest);
          setFormData((prevFormData) => ({
            ...prevFormData,
            restaurant_id: rest.id,
            quantity: 10

          }));
        });
    }
  }, [userr]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Send POST request
    fetch('/foods', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then((response) => {
        if (response.ok) {
       
        setIsSuccess(true);
        setMessage('Food Added successfully!');
        } else {
        setIsSuccess(false);
        setMessage('Failed to add food. Please try again.');
        }
      })
      .then((data) => {
        // Handle response data if needed
      })
      .catch((error) => {
        // Handle errors
      });
  };

  if (userr) {
    if (userr.user_type !== "admin") {
      window.location.href = "../";
    }
  }

	return (
	  <>
	   {userr && (
    		<div className="main-section">
<AdminHeader userr={userr}/>
			<div className="page-section account-header buyer-logged-in">
				<div className="container">
					<div className="row">
					<AdminSidebar />
						<div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
							<div className="user-dashboard loader-holder">
							<div className="user-holder">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<div className="row">
											<div className="element-title has-border right-filters-row">
												
                                                {/* Title  */}
                                                <h5>Add Food</h5><br/>
												{message && (
                              <p
                                style={{
                                  backgroundColor: isSuccess
                                    ? 'green'
                                    : 'red',
                                  color: 'white',
                                  padding: '10px',
                                  marginBottom: '10px',
                                  borderRadius: '5px',
                                }}
                              >
                                {message}
                              </p>
                            )}
										
											</div>
										</div>
									</div>



                                    <form onSubmit={handleFormSubmit}>

										<div className="form-fields-set">
											<ul>
												<li>
													<div className="row">
														<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
															<div className="restaurant-info">
																<div className="img-holder">
																	<ul className="foodbakery-gallery-holder">
																		<li className="gal-img">
																			<div className="drag-list">
																				<div className="item-thumb">
																					<img className="thumbnail" src={`${process.env.PUBLIC_URL}/assets/extra-images/one.jpg`} alt=""/>
																				</div>
																				<div className="item-assts">
																					<ul className="list-inline pull-right">
																						<li className="close-btn"><a ><i className="icon-cross-out"></i></a></li>
																					</ul>
																				</div>
																			</div>
																		</li>
																	</ul>
																</div>
																<div className="text-holder">


																	<strong>Food Image</strong>
																	
                                                                    
                                                                    <div className="upload-gallery">
																		<input className="foodbakery-dev-gallery-uploader" style={{display:"none"}}  type="file"/>
																		<a  className="upload-btn foodbakery-dev-featured-upload-btn">Upload Image</a>
																	</div>
																	<span>. Max Upload Size: 10MB.</span>
																</div>
															</div>
														</div>
													
														<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
															<div className="field-holder">
																<label>Name*</label>
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    className="foodbakery-dev-req-field"
                                                                    placeholder="Food Name"
                                                                    value={formData.name}
                                                                    onChange={handleFormChange}
                                                                />

															</div>
														</div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
															<div className="field-holder">
																<label>Description</label>
                                                                <input
                                                                    type="text"
                                                                    name="description"
                                                                    className="foodbakery-dev-req-field"
                                                                    placeholder="Food Description"
                                                                    value={formData.description}
                                                                    onChange={handleFormChange}
                                                                />   
															</div>
														</div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
															<div className="field-holder">
																<label>Price</label>
                                                                <input
                                                                    type="text"
                                                                    name="price"
                                                                    className="foodbakery-dev-req-field"
                                                                    placeholder="Food price"
                                                                    value={formData.price}
                                                                    onChange={handleFormChange}
                                                                />                                                               
															</div>
														</div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
															<div className="field-holder">
																<label>cuisine</label>
																<select 
                                
                                
                                name='cuisine_id'
                                className="chosen-select"
                                value={formData.cuisine_id}
                                onChange={handleFormChange}
                                
                                
                                >
                                       <option value="">Select Cuisine</option>

                                {cuisines && cuisines.map((cuisine, index) => (
	                                  <option value={cuisine.id}>{cuisine.name}</option>
														))}	

																</select>
															</div>
														</div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
															<div className="field-holder">
																<label>Food Type</label>
																<select  
                                    name='foodtype'
                                    className="chosen-select"
                                    value={formData.foodtype}
                                    onChange={handleFormChange}
                                    required
                                    >
                                       <option value="">Select Food Type</option>
																	<option value="0">Fast Food</option>
																	<option value="1">Others</option>
																</select>
															</div>
														</div>
								
													
                                                      
													</div>
												</li>
											</ul>
											<div>
												<div className="field-holder">
													<div className="payment-holder input-button-loader">
														<input className="update-restaurant" type="submit" value="Add Food" onclick="location.href='#top';"/>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	   )}
    </>
  )
}

export default AdminAddfood