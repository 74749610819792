import React from 'react';

function CheckoutEncryptionTest() {
    const IVKey = new TextEncoder().encode("fNBsQiadHm6hjRwn");
    const secretKey = new TextEncoder().encode("VzxVLWyOQQsa2ZiM");
    const algorithm = { name: 'AES-CBC', iv: IVKey };
    
    // Sample payload object
    const payloadObj = {
      "merchant_transaction_id": "TXN10001",
      "customer_first_name": "Test",
      "customer_last_name": "User",
      "msisdn": 123456,
      "customer_email": "testuser@gmail.com",
      "request_amount": 10.0,
      "currency_code": "KSH",
      // ... other payload properties
    };
    
    const payloadStr = JSON.stringify(payloadObj);
    const payloadData = new TextEncoder().encode(payloadStr);
    
    (async function() {
      try {
        const secretKeyBuffer = await crypto.subtle.importKey(
          "raw",
          secretKey,
          { name: "AES-CBC" },
          false,
          ["encrypt"]
        );
    
        const encryptedData = await crypto.subtle.encrypt(algorithm, secretKeyBuffer, payloadData);
    
        const encryptedPayload = btoa(String.fromCharCode(...new Uint8Array(encryptedData)));
        console.log("Encrypted Payload:", encryptedPayload);
      } catch (error) {
        console.error("Encryption error:", error);
      }
    })();
  return (
    <div>hello testing</div>
  );
}

export default CheckoutEncryptionTest;
