import React from 'react'

function Home() {
  return (
    <>


<div className="main-section">
			<div className="page-section nopadding cs-nomargin" style={{
      marginTop: '0px',
      paddingTop: '200px',
      paddingBottom: '0px',
      marginBottom: '0px',
      background: 'url(assets/extra-images/home-top-1.jpg) no-repeat center / cover'
    }}>
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
							<div className="element-title">
								<h1 style={{
      color: '#ffffff',
      fontSize: '48px',
      letterSpacing: '1px',
      lineHeight: '54px',
      textAlign: 'center',
      fontWeight: 600,
      textTransform: 'uppercase',
    }}>
									Order Now and Save Time</h1>
								<p style={{
      color: '#ffffff',
      fontSize: '24px',
      lineHeight: '32px',
      textAlign: 'center',
      textTransform: 'uppercase',
    }}>
									Order Ahead</p>
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="row">
								<div className="listing-main-search">
									<div className="main-search classic">
										<form>
											<div className="restaurant-search-element-container">
												<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
													<div className="field-holder">
														<span className="restaurant-element-search-btn"> <i className="icon-search5"></i> </span>
														<div className="select-location">
															<div className="foodbakery-locations-fields-group foodbakery-focus-out">
																<span id="foodbakery_radius_location_open333" className="foodbakery-radius-location"><i className="icon-target5"></i></span>
																<input type="text" className="location-field-text filter" placeholder="All Locations"/>
															</div>
															<div className="select-location" id="foodbakery-radius-range333" style={{ display: 'none' }}>
																<div className="select-popup popup-open">
																	<a id="location_close_popup333" href="javascript:0;" className="location-close-popup">
																		<i className="icon-times"></i></a>
																	<input type="hidden" className="foodbakery-radius" name="foodbakery_radius" value="10"/>
																	<p>Show with in</p>
																	<input id="ex16b333" type="text" data-value="10" value="10" style={{ display: 'none' }}/>
																
																	<span>Miles: <span id="ex16b333CurrentSliderValLabel">10</span></span><br/>
																	<p className="my-location">of <i className="cs-color icon-location-arrow"></i><a id="foodbakery-geo-location-all" className="cs-color foodbakery-geo-location313324" href="javascript:void(0)">My location</a></p>
																</div>
															</div>
															<input value="Search" onClick="location.href='#top'" type="button"/>
														</div>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ height: '140px' }}></div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="categories-holder fancy">
								<div className="row">
									<div className="col-lg-2 col-sm-4 col-xs-6">
										<div className="categories-list">
											<a >
												<img alt="#" src="assets/extra-images/top-icon-1.png"/>
												<h6 style={{ color: '#ffffff' }}>BreakFast</h6>
											</a>
										</div>
									</div>
									<div className="col-lg-2 col-sm-4 col-xs-6">
										<div className="categories-list">
											<a >
												<img alt="#" src="assets/extra-images/top-icon-1-03.png"/>
												<h6 style={{ color: '#ffffff' }}>Dinner</h6>
											</a>
										</div>
									</div>
									<div className="col-lg-2 col-sm-4 col-xs-6">
										<div className="categories-list">
											<a >
												<img alt="#" src="assets/extra-images/top-icon-1-02.png"/>
												<h6 style={{ color: '#ffffff' }}>Lunch</h6>
											</a>
										</div>
									</div>
									<div className="col-lg-2 col-sm-4 col-xs-6">
										<div className="categories-list">
											<a >
												<img alt="#" src="assets/extra-images/top-icon-1-04.png"/>
												<h6 style={{ color: '#ffffff' }}>Delivery</h6>
											</a>
										</div>
									</div>
									<div className="col-lg-2 col-sm-4 col-xs-6">
										<div className="categories-list">
											<a ><img alt="#" src="assets/extra-images/top-icon-1-05.png"/>
												<h6 style={{ color: '#ffffff' }}>Nightlife</h6>
											</a>
										</div>
									</div>
									<div className="col-lg-2 col-sm-4 col-xs-6">
										<div className="categories-list">
											<a >
												<img alt="#" src="assets/extra-images/top-icon-1-06.png"/>
												<h6 style={{ color: '#ffffff' }}>Take Away</h6>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

            
			<div className="page-section nopadding cs-nomargin" style={{
      marginTop: '0px',
      paddingTop: '40px',
      paddingBottom: '40px',
      marginBottom: '40px',
      background: '#eb6825'
    }}>
				<div className="container ">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
							<div className="counter-sec">
								<div className="row">
									<div className="col-lg-4 col-md-4 col-xs-4 ">
										<div className="counter-holder">
											<div className="text-holder">
												<i className="icon- icon-check-circle" style={{ color: '#ffffff' }}></i>
												<strong className="count" style={{ color: '#ffffff' }}>18</strong>
												<span style={{ color: '#ffffff' }}>Restaurant</span>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-4 col-xs-4 ">
										<div className="counter-holder">
											<div className="text-holder">
												<i className="icon- icon-check-circle" style={{ color: '#ffffff' }}></i>
												<strong className="count" style={{ color: '#ffffff' }}>9</strong>
												<span style={{ color: '#ffffff' }}>People Served</span>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-4 col-xs-4 ">
										<div className="counter-holder">
											<div className="text-holder">
												<i className="icon- icon-check-circle" style={{ color: '#ffffff' }}></i>
												<strong className="count" style={{ color: '#ffffff' }}>49</strong>
												<span style={{ color: '#ffffff' }}>Registered Users</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
			</div>

            </div>
			<div className="page-section" style={{
      marginTop: '0px',
      paddingTop: '100px',
      paddingBottom: '40px',
      marginBottom: '0px',
      background: 'url(assets/extra-images/banner-img.jpg) no-repeat center / cover'
    }}>
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="center">
								<div style={{
      paddingLeft: '10px',
      paddingRight: '10px',
      marginTop: '10px',
      marginBottom: '10px',
    }} className="cs-spreator fancy">
									<div className="cs-seprater" style={{ textAlign: 'center' }}>
										<span><img src="assets/images/divider.png" alt="#"/></span>
									</div>
								</div>
							</div>
						</div>
					

						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="element-title">
								<h1  style={{
      color: '#3d4d67',
      fontSize: '28px',
      letterSpacing: '1px',
      lineHeight: '34px',
      textAlign: 'center',
      fontStyle: 'normal'
    }}>
									How It Works</h1>
								<p style={{ textAlign: 'center' }}>Easy 3 Step Orders</p>
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="cs-icon-boxes-list classic-view top-center">
								<div className="row">
									<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
										<div className="icon-boxes classic top-center">
											<div className="img-holder">
												<figure>
													<a ><img src="assets/extra-images/icon-1.png" alt="Choose a Restaurant"/></a>
												</figure>
											</div>
											<div className="text-holder">
												<h3 style={{ color: '#3d4d67' }}>
													<a  style={{ color: '#3d4d67' }}>Choose a Restaurant</a>
												</h3>
												<p>We make it simple to find the food you crave. Enter your address and
													let us do the rest.</p>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
										<div className="icon-boxes classic top-center">
											<div className="img-holder">
												<figure>
													<a ><img src="assets/extra-images/icon-2.png" alt="Choose a Tasty Dish"/></a>
												</figure>
											</div>
											<div className="text-holder">
												<h3 style={{ color: '#3d4d67' }}>
													<a  style={{ color: '#3d4d67' }}>Choose a Tasty Dish</a>
												</h3>
												<p>When you order Food ChapChap, we'll hook you up with exclusive coupons,
													specials and rewards.</p>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
										<div className="icon-boxes classic top-center">
											<div className="img-holder">
												<figure>
													<a ><img src="assets/extra-images/icon-1.png" alt="Pick up or Delivery"/></a>
												</figure>
											</div>
											<div className="text-holder">
												<h3 style={{ color: '#3d4d67' }}>
													<a  style={{ color: '#3d4d67' }}>Pick up or Delivery</a>
												</h3>
												<p>Come and Eat your Favourite Mean at your Favourite restaurant</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>


					</div>
				</div>
			</div>

			<div className="page-section nopadding cs-nomargin" style={{
      marginTop: '0px',
      paddingTop: '60px',
      paddingBottom: '90px',
      marginBottom: '-136px',
      background: 'url(assets/extra-images/main-heading-bg.jpg) no-repeat center top / cover',
      backgroundAttachment: 'fixed',
      backgroundColor: '#ffffff'
    }}>
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
							<div className="element-title">
								<h1 style={{
      color: '#ffffff',
      fontSize: '28px',
      letterSpacing: '1px',
      lineHeight: '34px',
      textAlign: 'center',
      fontStyle: 'normal',
    }}>
									Choose From Most Popular</h1>
								<p style={{
      color: '#ffffff',
      fontSize: '28px',
      letterSpacing: '1px',
      lineHeight: '34px',
      textAlign: 'center',
      fontStyle: 'normal',
    }}>All the top restaurant in your city</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="page-section nopadding cs-nomargin" style={{
      marginTop: '0px',
      paddingTop: '60px',
      paddingBottom: '28px',
      marginBottom: '0px',
      borderBottom: '1px solid #f3f3f3'
    }}>
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="company-holder fancy">
								<div className="swiper-container">
									<div className="swiper-wrapper">
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/coffee.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Premium Coffee</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/wendy.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Wendy’s Cafe</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/mcdonals.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >McDonald’s</a> </h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/nando.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Nando’s</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/juicy.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Juicy Power</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/menu-1.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Bakery Cookies</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/jet.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Jet’s Kitchen</a></h6>
												</div>
												<div className="company-rating">
													<div className="rating-star">
														<span className="rating-box" style={{ width: '100%' }}></span>
													</div>
													<span className="reviews">(1)</span>
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/goldenchick.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Golden Chick Fries</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/dragon.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Dragon Express</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/healthy.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Healthy Food</a></h6>
												</div>
												<div className="company-rating">
													<div className="rating-star">
														<span className="rating-box" style={{ width: '100%' }}></span>
													</div>
													<span className="reviews">(1)</span>
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/menu.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Menu &amp; Drinks</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/meatlover.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Meat Lovers</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/subway.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Subway Surfers</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/bking.png" alt=""/> </a>
												</figure>
											</div>

											<div className="text-holder">
												<div className="post-title">
													<h6><a >Burger King</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/restaurant.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >R &amp; Cuisine</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/pizzahut.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Pizza Hut</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/domino.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Domino’s Pizza</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/kfc.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >KFC – Kentucky</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/hardees.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Hardee’s</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/tacobell.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Taco Bell</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/noodle.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Noodle Menu</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/coffee.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Premium Coffee</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/wendy.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Wendy’s Cafe</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/mcdonals.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >McDonald’s</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/nando.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Nando’s</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/juicy.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Juicy Power</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="company-logo">
												<figure>
													<a ><img src="assets/extra-images/menu-1.png" alt=""/> </a>
												</figure>
											</div>
											<div className="text-holder">
												<div className="post-title">
													<h6><a >Bakery Cookies</a></h6>
												</div>
												<div className="company-rating">
													Reviews Coming Soon
													<div className="list-option">
														<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="fancy-button-prev"> <i className="icon-arrow_back"></i></div>
								<div className="fancy-button-next"><i className="icon-arrow_forward"></i></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="page-section nopadding cs-nomargin" style={{
      marginTop: '0px',
      paddingTop: '70px',
      paddingBottom: '40px',
      marginBottom: '0px',
      background: '#f2f6fb'
    }}>
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="center">
								<div style={{
      paddingLeft: '10px',
      paddingRight: '10px',
      marginTop: '10px',
      marginBottom: '10px',
    }} className="cs-spreator fancy">
									<div className="cs-seprater" style={{ textAlign: 'center' }}>
										<span><img src="assets/images/divider.png" alt="#"/></span>
									</div>
								</div>
							</div>
						</div>
						<div className="cs-section-title" style={{ textAlign: 'center' }}>
							<h2>Choose Popular Foods</h2>
							<span style={{  color: '#aaaaaa',}}>Explore restaurants, bars, and cafés by locality</span>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="row">
								<div className="listing grid-listing three-cols">
									<div className="col-md-4 col-xs-6 grid-listing-col ">
										<div className="img-holder">
											<figure>
												<a ><img src="assets/extra-images/cover-photo20-359x212.jpg" alt=""/> </a>
												<figcaption className="listing-meta">
													<div className="listing-inner clearfix">
														<div className="list-option">
															<span className="label bgcolor time-label" style={{ display: 'none' }}></span>
															<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </a>
														</div>
														<div className="list-rating">
															<div className="rating-star">
																<span className="rating-box" style={{ width: '100%' }}></span>
															</div>
															<span className="reviews">(1)</span>
														</div>
													</div>
												</figcaption>
											</figure>
											<span className="restaurant-status close"><em className="bookmarkRibbon"></em>Close</span>
										</div>
										<div className="text-holder">
											<div className="listing-inner">
												<h4><a >Nature Healthy Food</a></h4>
												<p>Apple Juice, Beef Roast, Cheese Burger</p>
												<div className="min-order">Min Order <span className="price">£15.00</span></div>
											</div>
											<div className="listing-footer">
												<div className="listing-inner clearfix">
													<div className="img-holder">
														<a ><img src="assets/extra-images/listing-logo03.png" alt=""/></a>
													</div>
													<div className="text-holder">
														<p>Bristol, Bristol</p>
														<p className="deliver-time"><span className="icon-motorcycle2"></span>10
															min</p>
														<p className="pickup-time"><span className="icon-clock4"></span>15 min
														</p>
														<a  className="ordernow-btn bgcolor">Order Now</a>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-4 col-xs-6 grid-listing-col ">
										<div className="img-holder">
											<figure>
												<a ><img src="assets/extra-images/cover-photo19-359x212.jpg" alt=""/></a>
												<figcaption className="listing-meta">
													<div className="listing-inner clearfix">
														<div className="list-option">
															<span className="label bgcolor time-label distance-1705" style={{ display: 'none' }}></span>
															<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i></a>
														</div>
													</div>
												</figcaption>
											</figure>
											<span className="restaurant-status open"><em className="bookmarkRibbon"></em>Open</span>
										</div>
										<div className="text-holder">
											<div className="listing-inner">
												<h4><a >Menu &amp; Drinks</a></h4>
												<p>Chicken Roast, Chines Soup, Cold Coffee</p>
												<div className="min-order">
													Min Order <span className="price">£10.00</span>
												</div>
											</div>
											<div className="listing-footer">
												<div className="listing-inner clearfix">
													<div className="img-holder">
														<a ><img src="assets/extra-images/listing-logo09.png" alt=""/></a>
													</div>
													<div className="text-holder">
														<p>Norfolk, Acle</p>
														<p className="deliver-time"><span className="icon-motorcycle2"></span>10
															min</p>
														<p className="pickup-time"><span className="icon-clock4"></span>15 min
														</p>
														<a  className="ordernow-btn bgcolor">Order Now</a>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-4 col-xs-6 grid-listing-col">
										<div className="img-holder">
											<figure>
												<a > <img src="assets/extra-images/cover-photo18-359x212.jpg" alt=""/></a>
												<figcaption className="listing-meta">
													<div className="listing-inner clearfix">
														<div className="list-option">
															<span className="label bgcolor time-label" style={{ display: 'none' }}></span>
															<a  className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i></a>
														</div>
													</div>
												</figcaption>
											</figure>
											<span className="restaurant-status close"><em className="bookmarkRibbon"></em>Close</span>
										</div>
										<div className="text-holder">
											<div className="listing-inner">
												<h4><a >Chefs</a></h4>
												<p>Egg Fry, Noodles, Pastry</p>
												<div className="min-order">
													Min Order <span className="price">£10.00</span>
												</div>
											</div>
											<div className="listing-footer">
												<div className="listing-inner clearfix">
													<div className="img-holder">
														<a ><img src="assets/extra-images/listing-logo12.png" alt=""/></a>
													</div>
													<div className="text-holder">
														<p>Cheshire, Chester</p>
														<p className="deliver-time"><span className="icon-motorcycle2"></span>10
															min</p>
														<p className="pickup-time"><span className="icon-clock4"></span>15 min
														</p>
														<a  className="ordernow-btn bgcolor">Order Now</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		

		
		</div>
       
    </>
  )
}

export default Home