import React, { useEffect, useState } from 'react'
import RestaurantSidebar from './RestaurantSidebar';
import RestaurantHeader from './RestaurantHeader';

function RestaurantReviews() {
	const [userr, setUserr] = useState(null);
	const [restaurant, setRestaurant] = useState([]);
  
	useEffect(() => {
	  // Fetch user information
	  fetch("/me")
		.then((response) => response.json())
		.then((user) => setUserr(user));
	}, []);
  
	useEffect(() => {
	  // Fetch user's orders when userr changes
	  if (userr) {
		fetch(`/restaurants/${userr.id}`)
		  .then((response) => response.json())
		  .then((rest) => setRestaurant(rest));
	  }
	}, [userr]);

if (userr) {
	
	if (userr.user_type !== "restaurant_owner") {
		window.location.href = "../";
	  }
}

	// useEffect(() => {
	// 	fetch(`/restaurants/${userr.id}`)
	// 	  .then((r) => r.json())
	// 	  .then((restaurant) => setRestaurant(restaurant))
	// 	  .catch((error) => {
	// 		console.error("Error fetching user:", error);
	// 	  });
	//   }, [userr]);

	
	const orders = restaurant.orders
	


	return (
	  <>
	   {userr && (
    		<div className="main-section">
		<RestaurantHeader userr={userr}/>
			<div className="page-section account-header buyer-logged-in">
				<div className="container">
					<div className="row">
					<RestaurantSidebar />
						<div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                        <div className="user-dashboard loader-holder">
								<div className="user-holder">
									<div className="dashbard-user-reviews-list">
										<div className="row">
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div className="element-title has-border reviews-header right-filters-row">
													<h5>
														<span>Reviews Given</span>
														<span className="element-slogan">(4)</span>
													</h5>
													<div className="right-filters row pull-right">
														<div className="col-lg-6 col-md-6 col-xs-6">
															<div className="sort-by">
																<ul className="reviews-sortby">
																	<li className="reviews-sortby-active">
																		<small>Sort by:</small>
																		<span><strong className="active-sort">Newest Reviews </strong></span>
																		<div className="reviews-sort-dropdown">
																			<form>
																				<div className="input-reviews">
																					<div className="radio-field">
																						<input name="review" id="check-1" type="radio" value="newest" checked="checked"/>
																						<label for="check-1">Newest Reviews</label>
																					</div>
																					<div className="radio-field">
																						<input name="review" id="check-2" type="radio" value="highest"/>
																						<label for="check-2">Highest Rating</label>
																					</div>
																					<div className="radio-field">
																						<input name="review" id="check-3" type="radio" value="lowest"/>
																						<label for="check-3">Lowest Rating</label>
																					</div>
																				</div>
																			</form>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="col-lg-6 col-md-6 col-xs-6 pull-right">
															<div className="input-field">
																<i className="icon-angle-down"></i>
																<input type="text" data-id="daterange223" id="daterange" value="" placeholder="Select Date Range"/>
												
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div className="user-reviews-list">
													<div className="review-listing">
														<ul>
															<li className="alert ">
																<div className="list-holder">
																	<div className="review-text">
																		<div className="review-title">
																			<h6><a >Buyer Demo: Awesome and Lovely Experience</a></h6>
																			<div className="rating-holder">
																				<div className="rating-star">
																					<span className="rating-box"  style={{width:"100%"}} ></span>
																				</div>
																			</div>
																		</div>
																		<em className="review-date">1 day Ago</em>
																		<p className="more">
																			It has all the bells and whistles I you are looking for in a Foodbakery directory theme. </p>
																		<a  className="review-reply-btn dashboard-review-reply-btn">
																			<i className="icon-reply"></i>Post a reply
																		</a>
																	</div>
																</div>
															</li>
															<li className="alert ">
																<div className="list-holder">
																	<div className="review-text">
																		<div className="review-title">
																			<h6><a >Buyer Demo: Delicious and Wealthy</a></h6>
																			<div className="rating-holder">
																				<div className="rating-star">
																					<span className="rating-box" style={{width:"100%"}}></span>
																				</div>
																			</div>
																		</div>
																		<em className="review-date">1 day Ago</em>
																		<p className="more">A+++ support from developers. Super system, all integrated very well documented and great support, this is great. </p>
																		<a  className="review-reply-btn dashboard-review-reply-btn"><i className="icon-reply"></i>Post a reply</a>
																	</div>
																</div>
															</li>
															<li className="alert ">
																<div className="list-holder">
																	<div className="review-text">
																		<div className="review-title">
																			<h6><a >: Best Food</a></h6>
																			<div className="rating-holder">
																				<div className="rating-star">
																					<span className="rating-box" style={{width:"100%"}}></span>
																				</div>
																			</div>
																		</div>
																		<em className="review-date">12 months Ago</em>
																		<p className="more">A wonderfull experience. </p>
																		<a  className="review-reply-btn dashboard-review-reply-btn"><i className="icon-reply"></i>Post a reply</a>
																	</div>
																</div>
															</li>
															<li className="alert ">
																<div className="list-holder">
																	<div className="review-text">
																		<div className="review-title">
																			<h6><a >: Best food</a></h6>
																			<div className="rating-holder">
																				<div className="rating-star">
																					<span className="rating-box" style={{width:"100%"}}></span>
																				</div>
																			</div>
																		</div>
																		<em className="review-date">12 months Ago</em>
																		<p className="more">
																			I had great food the other day </p>
																		<a  className="review-reply-btn dashboard-review-reply-btn"><i className="icon-reply"></i>Post a reply</a>
																	</div>
																</div>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="dashboard-add-new-review-holder add-new-review-holder" style={{display:"none"}} >
										<div className="row">
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div className="elements-title">
													<h3>Rate and Write a Review</h3>
													<a  className="dashboard-close-post-new-reviews-btn close-post-new-reviews-btn">Close</a>
												</div>
											</div>
											<div className="foodbakery-add-review-data">
												<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
													<div className="form-element">
														<i className="icon-edit2"></i>
														<input type="text" placeholder="Title of your Comment *" value=""/>
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
													<div className="form-element">
														<i className="icon-user4"></i>
														<input type="text" placeholder="Name *" value="resturant-demo" disabled="disabled"/>
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
													<div className="form-element">
														<i className="icon-envelope3"></i>
														<input type="text" placeholder="Email *" value="dum32@chimpgroup.com"/>
													</div>
												</div>
												<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
													<div className="form-element">
														<i className="icon-message"></i>
														<textarea placeholder="Tell about your experience or leave a tip for others" cols="30" rows="10" maxlength="0"></textarea>
													</div>
												</div>
												<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
													<div className="form-element message-length">
														<span className="min_char">Min characters: 0</span>
														<span className="max_char">Max characters: 0</span>
														<div id="textarea_feedback"></div>
													</div>
												</div>
												<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
													<div className="form-element">
														<div className="review-reply-button input-button-loader">
															<input type="button" value="Submit your Review"/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	   )}
    </>
  )
}

export default RestaurantReviews